import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Container, Row } from "react-bootstrap";
import "./Footer.css";

const Footer = () => {
  return (
    <>
      <section className="footer">
        <Container>
          <Row className="footer_wrapper">
            <Col xs={12} md={12} lg={3} className="logo_footer">
              <img
                src={require("../../../Images/logo_footer_jobfair.png").default}
                alt=""
              />
            </Col>
            <Col xs={12} md={12} lg={9} className="footer_detail">
              <div className="footer_detail_wrapper">
                <h4>Co-operative Education and Job Placement Center</h4>
                <div className="sub_footer_detail">
                  <h5>
                    Address:1771/1 Pattanakarn Rd, Suan Luang Bangkok
                    10250,Thailand
                  </h5>
                  <h5>Telephone:02-763-2600 #2762, Email: kanidta@tni.ac.th</h5>
                  <div className="icon_and_copyright">
                    <div className="icon_footer">
                      <FontAwesomeIcon icon={faFacebook} />
                      <FontAwesomeIcon icon={faEnvelope} />
                    </div>
                    <div className="line_footer"></div>
                    <h5>Copy right All Reserved,Designed</h5>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Footer;
