import { useEffect, useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { ServerHost } from "../../ServerConfig/ServerConfig";
import "./ModalVerticalCenterChooseJob.css";
const ModalVerticalCenterChooseJob = (props) => {
  const [jobTableDataAll, setJobTableDataAll] = useState([
    { id: 0, nameTH: "ไม่พบ", job_name: "ไม่พบ" },
  ]);
  const [searchInput, setSearchInput] = useState("");
  const { show } = props;

  function loadJobAllToTable() {
    fetch(`${ServerHost}/api/getJobAll`)
      .then((response) => response.json())
      .then((result) => {
        setJobTableDataAll(result.data);
      });
  }

  useEffect(() => {
    loadJobAllToTable();
  }, [show]);
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Control
          size="lg"
          type="text"
          placeholder="ค้นหา"
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
        />
        <br />
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>ชื่อบริษัท</th>
              <th>ชื่องาน</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {searchInput.trim() === "" &&
              jobTableDataAll.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.nameTH}</td>
                    <td>{item.job_name}</td>
                    <td>
                      <Button
                        variant="primary"
                        type="submit"
                        style={{ padding: "0 20px" }}
                        onClick={() => {
                          console.log(item.id);
                          props.onSetJobId(item.id);
                        }}
                      >
                        เลือก
                      </Button>
                    </td>
                  </tr>
                );
              })}
            {searchInput.trim() !== "" &&
              jobTableDataAll
                .filter((item, index) => {
                  return (
                    item.job_name.includes(searchInput) ||
                    item.nameTH.includes(searchInput)
                  );
                })
                .map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.nameTH}</td>
                      <td>{item.job_name}</td>
                      <td>
                        <Button
                          variant="primary"
                          type="submit"
                          style={{ padding: "0 20px" }}
                          onClick={() => {
                            console.log(item.id);
                            props.onSetJobId(item.id);
                          }}
                        >
                          เลือก
                        </Button>
                      </td>
                    </tr>
                  );
                })}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalVerticalCenterChooseJob;
