import { Col, Container, Row } from "react-bootstrap";
import CardCompany from "../../UI/CardCompany/CardCompany";
import "./ThereMoreCompany.css";
const ThereMoreCompany = (props) => {
  return (
    <section className="there_more_company">
      <Container>
        <div className="there_more_company_header">
          <h2>there more</h2>
          <h1>Many Interesting Companies</h1>
        </div>
        <Row>
          {props.DetailData.company_interest.map((item, index) => (
            <Col lg="4" key={index}>
              <CardCompany
                logo={Buffer(item.logo).toString()}
                company_name_en={item.nameEN}
                company_name_th={item.nameTH}
                id={item.id}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default ThereMoreCompany;
