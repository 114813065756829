import { useCallback, useEffect, useState } from "react";
/* import { useParams } from "react-router-dom"; */
import CardMenu from "../../Layouts/CardMenu/CardMenu";
import CarouselBanner from "../../Layouts/CarouselBanner/CarouselBanner";
import FacebookLive from "../../Layouts/FacebookLive/FacebookLive";
/* import Partner from "../../Layouts/Partner/Partner";
import Recruitment from "../../Layouts/Recruitment/Recruitment"; */
import TniJobFairTimeLine from "../../Layouts/TniJobFairTimeLine/TniJobFairTimeLine";
import { ServerHost } from "../../ServerConfig/ServerConfig";

const Home = () => {
  /* const { id } = useParams(); */
  const [homeData, setHomeData] = useState({
    stream_link: "",
    partner: [],
    partnerQty: 0,
    job: [],
    jobQty: 0,
  });

  const getDataForHomePage = useCallback(async () => {
    const [partner, job, partnerQty, jobQty, stream_link] = await Promise.all([
      fetch(`${ServerHost}/api/getCompany6Value`).then((response) =>
        response.json()
      ),
      fetch(`${ServerHost}/api/getJob4Value`).then((response) =>
        response.json()
      ),
      fetch(`${ServerHost}/api/getCompanyQtyValue`).then((response) =>
        response.json()
      ),
      fetch(`${ServerHost}/api/getJobQtyValue`).then((response) =>
        response.json()
      ),
      fetch(`${ServerHost}/api/getLiveStream`).then((response) =>
        response.json()
      ),
    ]);
    setHomeData({
      ...homeData,
      partner: partner.data,
      partnerQty: partnerQty.data[0].qty,
      job: job.data,
      jobQty: jobQty.data[0].qty,
      stream_link: stream_link.data.link,
    });
  }, [homeData]);
  /*   async function showMorePartner() {
    const partnerAll = await fetch(`${ServerHost}/api/getCompanyAllValue`).then(
      (response) => response.json()
    );

    setHomeData({ ...homeData, partner: partnerAll.data });
  }
  async function showMoreJob() {
    const job = await fetch(`${ServerHost}/api/getJobAllValue`).then(
      (response) => response.json()
    );
    console.log(job);
    setHomeData({ ...homeData, job: job.data });
  }
  async function showLessPartner() {
    const partnerAll = await fetch(`${ServerHost}/api/getCompany6Value`).then(
      (response) => response.json()
    );

    setHomeData({ ...homeData, partner: partnerAll.data });
  }
  async function showLessJob() {
    const job = await fetch(`${ServerHost}/api/getJob4Value`).then((response) =>
      response.json()
    );
    console.log(job);
    setHomeData({ ...homeData, job: job.data });
  } */
  useEffect(() => {
    window.scrollTo(0, 0);
    getDataForHomePage();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <CarouselBanner />
      <FacebookLive stream_link={homeData.stream_link} />
      <CardMenu />
      {/* <Partner
        partner={homeData.partner}
        partnerQty={homeData.partnerQty}
        showMorePartner={showMorePartner}
        showLessPartner={showLessPartner}
        id={id}
      />
      <Recruitment
        job={homeData.job}
        jobQty={homeData.jobQty}
        showMoreJob={showMoreJob}
        showLessJob={showLessJob}
        id={id}
      /> */}
      <TniJobFairTimeLine />
    </>
  );
};

export default Home;
