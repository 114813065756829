import { env } from "./env.js";
let Config;
let ServerHost;
if (env.mode === "dev") {
  Config = {
    host: "http://localhost",
    port: "4001",
  };
  ServerHost = Config.host + ":" + Config.port;
} else if (env.mode === "prod") {
  Config = {
    /* host: "https://150.95.80.183/plesk-site-preview/api.jobfair.tni.ac.th/https/150.95.80.183", */
    host: "https://jobfair.tni.ac.th",
    port: "",
  };
  ServerHost = Config.host;
}

export { ServerHost };
