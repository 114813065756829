import { Link } from "react-router-dom";
import "./ShowLess.css";

const ShowLess = (props) => {
  return (
    <div className="show_less">
      <Link to={props.href} onClick={props.onClick}>
        แสดงน้อยลง
      </Link>
    </div>
  );
};

export default ShowLess;
