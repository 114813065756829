import { useEffect } from "react";
import { Container } from "react-bootstrap";
import "./CompanyDetailContact.css";
const CompanyDetailContact = (props) => {
  useEffect(() => {
    let tabHeader = document.getElementsByClassName(
      "company_detail_contact_tab_header"
    )[0];
    let tabSelect = tabHeader.getElementsByClassName(
      "company_detail_contact_tab_select"
    );
    let tabIndicator = document.getElementsByClassName(
      "company_detail_contact_tab_indicator"
    )[0];
    let tabBody = document.getElementsByClassName(
      "company_detail_contact_tab_body"
    )[0];
    for (let i = 0; i < tabSelect.length; i++) {
      tabSelect[i].addEventListener("click", () => {
        tabHeader
          .getElementsByClassName("active")[0]
          .classList.remove("active");
        tabSelect[i].classList.add("active");
        tabIndicator.style.left = `calc(calc(100% / 3) * ${i})`;
        tabBody.getElementsByClassName("active")[0].classList.remove("active");
        tabBody
          .getElementsByClassName("company_detail_contact_tab_body_content")
          [i].classList.add("active");
      });
    }
  }, []);
  return (
    <>
      <section className="company_detail_contact_tab_section">
        <Container>
          <div className="company_detail_contact_tab">
            <div className="company_detail_contact_tab_header">
              <div className="company_detail_contact_tab_select active">
                Company Details
              </div>
              <div className="company_detail_contact_tab_select">
                Contact us
              </div>
            </div>
            <div className="company_detail_contact_tab_indicator"></div>
          </div>
        </Container>
      </section>
      <section>
        <Container>
          <div className="company_detail_contact_tab_body">
            <div className="company_detail_contact_tab_body_content active">
              {props.companyDetailData.company_topic_and_detail.map(
                (item, index) => (
                  <div key={index} className="company_detail_paragraph">
                    <h3>{item.company_detail_header}</h3>
                    <h4>{item.company_detail_paragraph}</h4>
                  </div>
                )
              )}
            </div>
            <div className="company_detail_contact_tab_body_content">
              <div className="company_detail_paragraph">
                <h3>Address Office</h3>
                <h4>
                  {props.companyDetailData.address}{" "}
                  {props.companyDetailData.subDistrict}{" "}
                  {props.companyDetailData.district}{" "}
                  {props.companyDetailData.province}{" "}
                  {props.companyDetailData.zipCode}
                </h4>
              </div>
              <div className="company_detail_paragraph">
                <h3>Telephone Number</h3>
                <h4>{props.companyDetailData.phone}</h4>
              </div>
              <div className="company_detail_paragraph">
                <h3>Working Days</h3>
                <h4>{props.companyDetailData.workTime}</h4>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default CompanyDetailContact;
