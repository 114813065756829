import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Col, Container, Row } from "react-bootstrap";
import CardCompany from "../../UI/CardCompany/CardCompany";
import ShowMore from "../../UI/ShowMore/ShowMore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./PartnerFinding.css";
import { useState } from "react";
import ShowLess from "../../UI/ShowLess/ShowLess";
const PartnerFinding = (props) => {
  const [isShowMore, setIsShowMore] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  return (
    <section className="partner_finding">
      <Container>
        <div className="partner_header_wrapper">
          <div>
            <h1 className="partner_finding_title">
              บริษัทพันธมิตร {props.partnerQty} บริษัท
            </h1>
            <h2 className="partner_finding_subtitle">
              ที่เราเลือกสรรงานคุณภาพ ให้ตรงความต้องการ
            </h2>
          </div>
          <input
            type="checkbox"
            className="searchJobToggle"
            id="searchJobToggle"
            style={{ display: "none" }}
          />
          <div className="searchJob">
            <div className="searchJobIconWrapper">
              <label htmlFor="searchJobToggle">
                <FontAwesomeIcon icon={faSearch} className="searchJobIcon" />
              </label>
            </div>
            <input
              type="text"
              className="searchJobInput"
              placeholder="ค้นหาบริษัทที่สนใจ"
              value={searchInput}
              onChange={(e) => {
                props.searchCompanyInput(e.target.value);
                setSearchInput(e.target.value);
              }}
            />
          </div>
        </div>
      </Container>
      <Container>
        <div className="wrapperCardPartnerFindingRow">
          <Row>
            {props.partner.map((item, index) => (
              <Col md={6} key={index}>
                <CardCompany
                  logo={Buffer(item.logo).toString()}
                  company_name_en={item.nameEN}
                  company_name_th={item.nameTH}
                  id={item.id}
                />
              </Col>
            ))}
          </Row>
        </div>
        {isShowMore === false && searchInput === "" && (
          <div className="show_more_card_partner">
            <ShowMore
              href="#"
              onClick={() => {
                setIsShowMore(true);
                props.showMorePartner();
              }}
            />
          </div>
        )}
        {isShowMore === true && searchInput === "" && (
          <div className="show_less_card_partner">
            <ShowLess
              href="#"
              onClick={() => {
                setIsShowMore(false);
                props.showLessPartner();
              }}
            />
          </div>
        )}
      </Container>
    </section>
  );
};

export default PartnerFinding;
