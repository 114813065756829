import { faMixcloud } from "@fortawesome/free-brands-svg-icons";
import { faCity, faSearch, faTools } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CardJob from "../../UI/CardJob/CardJob";
import ShowLess from "../../UI/ShowLess/ShowLess";
import ShowMore from "../../UI/ShowMore/ShowMore";
import "./RecruitmentFinding.css";
const RecruitmentFinding = (props) => {
  const [type, setType] = useState("Engineer");
  const [searchInput, setSearchInput] = useState("");
  useEffect(() => {
    let tabRecruitmentHeader = document.getElementsByClassName(
      "tabRecruitmentHeader"
    )[0];
    let tabRecruitmentIndicator = document.getElementsByClassName(
      "tabRecruitmentIndicator"
    )[0];
    let tabRecruitmentBody =
      document.getElementsByClassName("tabRecruitmentBody")[0];
    let tabsPane = tabRecruitmentHeader.getElementsByClassName(
      "tabRecruitmentContent"
    );

    for (let i = 0; i < tabsPane.length; i++) {
      tabsPane[i].addEventListener("click", () => {
        tabRecruitmentHeader
          .getElementsByClassName("active")[0]
          .classList.remove("active");
        tabsPane[i].classList.add("active");

        tabRecruitmentBody
          .getElementsByClassName("active")[0]
          .classList.remove("active");
        tabRecruitmentBody
          .getElementsByClassName("tabRecruitmentContent")
          [i].classList.add("active");
        tabRecruitmentIndicator.style.left = `calc(calc(100% / 3) * ${i})`;
      });
    }
    setType("Engineer");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  return (
    <>
      <section className="RecruitmentFindingTopic">
        <Container>
          <div className="RecruitmentFindingTopic_Wrapper">
            <h1>Recruitment Position | {props.jobQty.all} Jobs</h1>
            <input
              type="checkbox"
              className="searchJobToggle"
              id="searchJobToggle"
              style={{ display: "none" }}
            />
            <div className="searchJob">
              <div className="searchJobIconWrapper">
                <label htmlFor="searchJobToggle">
                  <FontAwesomeIcon icon={faSearch} className="searchJobIcon" />
                </label>
              </div>
              <input
                type="text"
                className="searchJobInput"
                placeholder="ค้นหางานที่สนใจ"
                value={searchInput}
                onChange={(e) => {
                  props.searchJobInput(e.target.value);
                  setSearchInput(e.target.value);
                }}
              />
            </div>
          </div>
        </Container>
      </section>
      {searchInput !== "" && (
        <div style={{ marginBottom: "100px" }}>
          <Container>
            {props.job.map((item, index) => (
              <CardJob
                key={index}
                logo={Buffer(item.logo).toString()}
                job_name={item.job_name}
                company_address_shortcut={`${item.district},${item.province}`}
                id={item.id}
              />
            ))}
          </Container>
        </div>
      )}
      <section
        className="tabRecruitmentSection"
        style={{ display: searchInput === "" ? "block" : "none" }}
      >
        <Container>
          <h3>Filter</h3>
          <div className="tabRecruitment">
            <div className="tabRecruitmentHeader">
              <div
                className="tabRecruitmentContent active"
                onClick={() => {
                  props.setJobFairData({
                    ...props.jobFairData,
                    jobType: "Engineer",
                  });
                }}
              >
                <Row>
                  <Col xs sm md="1" lg="2" className="tabRecruitmentIcon">
                    <FontAwesomeIcon icon={faTools} />
                  </Col>
                  <Col md="11" lg="9" className="tabRecruitmentDetail">
                    <h3>Bachelor of Engineer</h3>
                    <h4>Available {props.jobQty.engineer} Jobs</h4>
                  </Col>
                </Row>
              </div>
              <div
                className="tabRecruitmentContent"
                onClick={() => {
                  props.setJobFairData({ ...props.jobFairData, jobType: "IT" });
                }}
              >
                <Row>
                  <Col xs sm md="1" lg="2" className="tabRecruitmentIcon">
                    <FontAwesomeIcon icon={faMixcloud} />
                  </Col>
                  <Col md="11" lg="9" className="tabRecruitmentDetail">
                    <h3>Bachelor of IT</h3>
                    <h4>Available {props.jobQty.it} Jobs</h4>
                  </Col>
                </Row>
              </div>
              <div
                className="tabRecruitmentContent"
                onClick={() => {
                  props.setJobFairData({
                    ...props.jobFairData,
                    jobType: "Business",
                  });
                }}
              >
                <Row>
                  <Col xs sm md="1" lg="2" className="tabRecruitmentIcon">
                    <FontAwesomeIcon icon={faCity} />
                  </Col>
                  <Col md="11" lg="9" className="tabRecruitmentDetail">
                    <h3>Bachelor of Business</h3>
                    <h4>Available {props.jobQty.business} Jobs</h4>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="tabRecruitmentIndicator"></div>
            <div className="tabRecruitmentBody">
              <div className="tabRecruitmentContent active"></div>
              <div className="tabRecruitmentContent"></div>
              <div className="tabRecruitmentContent"></div>
            </div>
          </div>
        </Container>
      </section>
      <section
        className="RecruitmentFindingDetail"
        style={{
          display: searchInput === "" ? "block" : "none",
        }}
      >
        <Container>
          {props.job.map((item, index) => (
            <CardJob
              key={index}
              logo={Buffer(item.logo).toString()}
              job_name={item.job_name}
              company_address_shortcut={`${item.district},${item.province}`}
              id={item.id}
            />
          ))}
        </Container>
        <div style={{ paddingTop: "25px", paddingBottom: "25px" }}>
          {props.jobFairData.showMore === false && (
            <div className="show_more_card_partner">
              <ShowMore
                href="#"
                onClick={() => {
                  props.setJobFairData({
                    ...props.jobFairData,
                    showMore: true,
                  });
                }}
              />
            </div>
          )}
          {props.jobFairData.showMore === true && (
            <div className="show_less_card_partner">
              <ShowLess
                href="#"
                onClick={() => {
                  props.setJobFairData({
                    ...props.jobFairData,
                    showMore: false,
                  });
                }}
              />
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default RecruitmentFinding;
