import { Link } from "react-router-dom";
import "./ShowMore.css";

const ShowMore = (props) => {
  return (
    <div className="show_more">
      <Link to={props.href} onClick={props.onClick}>
        แสดงเพิ่มเติม
      </Link>
    </div>
  );
};

export default ShowMore;
