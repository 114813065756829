import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
/* import { Link } from "react-router-dom"; */
import "./ActivityArchive.css";
const ActivityArchive = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);
  return (
    <section className="activity_archive">
      <Container>
        <h1 className="activity_archive_topic">คลังกิจกรรม</h1>
        <Row>
          <Col md="6" lg="6">
            <a
              href="https://drive.google.com/drive/folders/1ZhQ8jZPcG7CtHvZxgvuYuTyR79QbwM3v?usp=sharing"
              style={{ textDecoration: "none" }}
              target={"_blank"}
              rel="noreferrer"
            >
              <img
                className="activity_archive_image_topic"
                src={require("../../../Images/main.jpg").default}
                alt=""
              ></img>
            </a>
          </Col>
          <Col md="6" lg="6">
            <div className="activity_archive_content_topic">
              {/* <h2>ล่าสุด</h2> */}
              <h1>TNIJOB FAIR</h1>
              <h4>
                สหกิจศึกษาและฝึกงาน ต่างประเทศสำหรับนักศึกษา
                สถาบันเทคโนโลยีไทย-ญีปุ่น
                {/* <Link to="#">ดูเพิ่มเติม...</Link> */}
              </h4>
            </div>
          </Col>
        </Row>
        <div className="activity_archive_old">
          <Row>
            <Col xs="6" md="3" lg="3">
              <a
                href="https://drive.google.com/drive/folders/1CWhcViBi3WcPSWpDfHOdLFfEtVEzD8n7?usp=sharing"
                style={{ textDecoration: "none" }}
                target={"_blank"}
                rel="noreferrer"
              >
                <div className="activity_archive_old_image1"></div>
                <div className="activity_archive_old_content">
                  <h5>เมื่อ 2 ปีที่แล้ว</h5>
                  <h2>TNIJOB FAIR 2020</h2>
                </div>
              </a>
            </Col>
            <Col xs="6" md="3" lg="3">
              <a
                href="https://drive.google.com/drive/folders/1Rx9D7qkDUQfE9KpOdqeNAUm_psc2YvYM?usp=sharing"
                style={{
                  textDecoration: "none",
                }}
                target={"_blank"}
                rel="noreferrer"
              >
                <div className="activity_archive_old_image2"></div>
                <div className="activity_archive_old_content">
                  <h5>เมื่อ 3 ปีที่แล้ว</h5>
                  <h2>TNIJOB FAIR 2019</h2>
                </div>
              </a>
            </Col>
            <Col xs="6" md="3" lg="3">
              <a
                href="https://drive.google.com/drive/folders/1ozPtnzYUmnjwD2133WGW299YVo-tc38Z?usp=sharing"
                style={{ textDecoration: "none" }}
                target={"_blank"}
                rel="noreferrer"
              >
                <div className="activity_archive_old_image3"></div>
                <div className="activity_archive_old_content">
                  <h5>เมื่อ 4 ปีที่แล้ว</h5>
                  <h2>TNIJOB FAIR 2018</h2>
                </div>
              </a>
            </Col>
            <Col xs="6" md="3" lg="3">
              <a
                href="https://drive.google.com/drive/folders/1ZL5ooWnYCAAcsnPvyKs0MI3_BeoJW1k7?usp=sharing"
                style={{ textDecoration: "none" }}
                target={"_blank"}
                rel="noreferrer"
              >
                <div className="activity_archive_old_image4"></div>
                <div className="activity_archive_old_content">
                  <h5>เมื่อ 5 ปีที่แล้ว</h5>
                  <h2>TNIJOB FAIR 2017</h2>
                </div>
              </a>
            </Col>
          </Row>
        </div>
        <section className="activity_archive_topic_section">
          <h1 className="activity_archive_topic">
            กิจกรรม และทุนโครงการแลกเปลี่ยน
          </h1>
          <Row>
            <Col xs="12" md="4" lg="4">
              <a
                href="https://www.tni.ac.th/home/student_portfolios"
                style={{ textDecoration: "none" }}
                target={"_blank"}
                rel="noreferrer"
              >
                <div className="activity_archive_old_image5"></div>
                <div className="activity_archive_old_content">
                  <h3>ผลงานนักศึกษา</h3>
                </div>
              </a>
            </Col>
            <Col xs="12" md="4" lg="4">
              <a
                href="https://www.tni.ac.th/home/exchange_programs"
                style={{ textDecoration: "none" }}
                target={"_blank"}
                rel="noreferrer"
              >
                <div className="activity_archive_old_image6"></div>
                <div className="activity_archive_old_content">
                  <h3>ทุนและโครงการแลกเปลี่ยน</h3>
                </div>
              </a>
            </Col>
            <Col xs="12" md="4" lg="4">
              <a
                href="https://www.tni.ac.th/home/cooperatives"
                style={{ textDecoration: "none" }}
                target={"_blank"}
                rel="noreferrer"
              >
                <div className="activity_archive_old_image7"></div>
                <div className="activity_archive_old_content">
                  <h3>สหกิจศึกษาและฝึกงาน</h3>
                </div>
              </a>
            </Col>
          </Row>
        </section>
      </Container>
    </section>
  );
};

export default ActivityArchive;
