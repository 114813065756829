import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { ServerHost } from "../../ServerConfig/ServerConfig";
import "./LiveStreamSetting.css";
const LiveStreamSetting = () => {
  const [liveStreamLink, setLiveStreamLink] = useState("");

  function saveLiveStreamLinkToDatabase(event) {
    fetch(`${ServerHost}/api/saveLiveStream`, {
      method: "POST",
      body: JSON.stringify({ link: liveStreamLink }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then(() => {
        alert("บันทึกเรียบร้อย");
      });
    event.preventDefault();
  }
  function getLiveStreamLink() {
    fetch(`${ServerHost}/api/getLiveStream`)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        setLiveStreamLink(result.data.link);
      });
  }

  useEffect(() => {
    getLiveStreamLink();
  }, []);

  return (
    <div className="LiveStreamSetting">
      <h4>ตั้งค่าไลฟ์วิดิโอ</h4>
      <Form onSubmit={saveLiveStreamLinkToDatabase}>
        <Form.Group className="mb-3">
          <Form.Label>ลิงค์ไลฟ์สตรีม</Form.Label>
          <Form.Control
            type="text"
            placeholder="ลิงค์ Live Stream"
            autoComplete="off"
            value={liveStreamLink}
            onChange={(event) => setLiveStreamLink(event.target.value)}
          />
          <Form.Text className="text-muted">
            คัดลอกลิงค์มาวาง height 315 width 560
          </Form.Text>
        </Form.Group>
        <Button variant="primary" type="submit">
          บันทึก
        </Button>
      </Form>
    </div>
  );
};

export default LiveStreamSetting;
