import { Link } from "react-router-dom";
import "./CardCompany.css";

const CardCompany = (props) => {
  return (
    <Link to={`/CompanyDetail/${props.id}`} className="card_company_link">
      <div className="card_company">
        <div className="card_company_content">
          <img
            className="card_company_logo"
            src={props.logo}
            alt={props.company_name_en}
          />
          <h1
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {props.company_name_en}
          </h1>
          <h2
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {props.company_name_th}
          </h2>
        </div>
      </div>
    </Link>
  );
};

export default CardCompany;
