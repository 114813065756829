import { useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { ServerHost } from "../../ServerConfig/ServerConfig";
import ModalVerticalCenterChooseCompany from "../../UI/ModalVerticalCenterChooseCompany/ModalVerticalCenterChooseCompany";
import "./AddCompanyDetail.css";

const AddCompanyDetail = () => {
  const [companyId, setCompanyId] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [companyTopicAndDetail, setCompanyTopicAndDetail] = useState([]);
  function getCompanyTopicAndDetail(id) {
    fetch(`${ServerHost}/api/getCompanyTopicAndDetail?companyId=${id}`)
      .then((response) => response.json())
      .then((result) => {
        setCompanyTopicAndDetail(result.data);
      });
  }
  function onSetCompanyId(id) {
    fetch(`${ServerHost}/api/getCompanyOnlyOne?id=${id}`)
      .then((response) => response.json())
      .then((result) => {
        setCompanyName(result.data[0].nameTH);
        setModalShow(false);
        getCompanyTopicAndDetail(id);
        setCompanyId(id);
      });
  }
  function saveCompanyTopicAndDetail(e) {
    e.preventDefault();
    fetch(`${ServerHost}/api/saveCompanyTopicAndDetail`, {
      method: "POST",
      body: JSON.stringify({ companyTopicAndDetail }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then(() => {
        getCompanyTopicAndDetail(companyId);
        alert("บันทึกสำเร็จ");
      });
  }

  console.log(companyTopicAndDetail);
  return (
    <div className="AddCompanyDetail">
      <div className="AddCompanyDetailHeader">
        <h4>เพิ่มรายละเอียดบริษัท</h4>
        <Button
          className="AddTopicButton"
          variant="primary"
          type="button"
          onClick={() => {
            if (companyId !== null) {
              setCompanyTopicAndDetail([
                ...companyTopicAndDetail,
                {
                  id: "",
                  company_id: companyId,
                  company_detail_header: "",
                  company_detail_paragraph: "",
                },
              ]);
            }
          }}
        >
          เพิ่มหัวข้อ
        </Button>
      </div>

      <Form onSubmit={saveCompanyTopicAndDetail}>
        <InputGroup className="mb-3">
          <Form.Control
            placeholder="เลือกบริษัท"
            aria-label="เลือกบริษัท"
            aria-describedby="basic-addon2"
            disabled
            value={companyName}
          />
          <Button
            variant="outline-secondary"
            id="button-addon2"
            onClick={() => setModalShow(true)}
          >
            เลือก
          </Button>
        </InputGroup>
        {companyId !== null &&
          companyTopicAndDetail.map((item, index) => {
            return (
              <Form.Group
                className="mb-3"
                key={index}
                style={{ position: "relative" }}
              >
                <Form.Label>กรุณาใส่หัวข้อและรายละเอียดตามลำดับ</Form.Label>
                <Button
                  type="button"
                  variant="danger"
                  style={{ position: "absolute", right: 0, top: "-6px" }}
                  onClick={() => {
                    fetch(`${ServerHost}/api/removeCompanyTopicAndDetail`, {
                      method: "POST",
                      body: JSON.stringify({ id: item.id }),
                      headers: {
                        "Content-Type": "application/json",
                      },
                    })
                      .then((response) => response.json())
                      .then((result) => {
                        alert(result.message);
                        getCompanyTopicAndDetail(companyId);
                      });
                  }}
                >
                  ลบ
                </Button>
                <Form.Control
                  type="hidden"
                  placeholder="หัวข้อ"
                  autoComplete="off"
                  value={item.id}
                />
                <Form.Control
                  type="text"
                  placeholder="หัวข้อ"
                  autoComplete="off"
                  value={item.company_detail_header}
                  onChange={(e) => {
                    setCompanyTopicAndDetail(
                      companyTopicAndDetail.map((subitem, subindex) => {
                        if (index !== subindex) return { ...subitem };
                        else
                          return {
                            ...subitem,
                            company_detail_header: e.target.value,
                          };
                      })
                    );
                  }}
                />
                <Form.Control
                  type="text"
                  placeholder="รายละเอียด"
                  autoComplete="off"
                  value={item.company_detail_paragraph}
                  onChange={(e) => {
                    setCompanyTopicAndDetail(
                      companyTopicAndDetail.map((subitem, subindex) => {
                        if (index !== subindex) return { ...subitem };
                        else
                          return {
                            ...subitem,
                            company_detail_paragraph: e.target.value,
                          };
                      })
                    );
                  }}
                />
              </Form.Group>
            );
          })}
        {companyId !== null && companyTopicAndDetail.length > 0 && (
          <>
            <Button variant="primary" type="submit">
              บันทึก
            </Button>
          </>
        )}
      </Form>

      <ModalVerticalCenterChooseCompany
        title={"บริษัทที่มี"}
        show={modalShow}
        onHide={() => setModalShow(false)}
        onSetCompanyId={onSetCompanyId}
      />
    </div>
  );
};

export default AddCompanyDetail;
