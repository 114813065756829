import { useEffect, useState } from "react";
import { Button, Modal, Form, Table } from "react-bootstrap";
import { ServerHost } from "../../ServerConfig/ServerConfig";
import "./ModalVerticalCenterChooseCompany.css";
const ModalVerticalCenterChooseCompany = (props) => {
  const [companyTableData, setCompanyTableData] = useState([
    { id: 0, nameTH: "ไม่พบ", nameEN: "ไม่พบ" },
  ]);
  const [searchInput, setSearchInput] = useState("");
  const { show } = props;
  function loadCompanyToTable() {
    fetch(`${ServerHost}/api/getCompany`)
      .then((response) => response.json())
      .then((result) => {
        console.log(result.data);
        setCompanyTableData(result.data);
      });
  }

  useEffect(() => {
    loadCompanyToTable();
  }, [show]);

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Control
          size="lg"
          type="text"
          placeholder="ค้นหา"
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
        />
        <br></br>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>ชื่อบริษัทไทย</th>
              <th>ชื่อบริษัทอังกฤษ</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {searchInput.trim() === "" &&
              companyTableData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.nameTH}</td>
                    <td>{item.nameEN}</td>
                    <td>
                      <Button
                        variant="primary"
                        type="submit"
                        style={{ padding: "0 20px" }}
                        onClick={() => {
                          props.onSetCompanyId(item.id);
                        }}
                      >
                        เลือก
                      </Button>
                    </td>
                  </tr>
                );
              })}

            {searchInput.trim() !== "" &&
              companyTableData
                .filter((item) => {
                  return (
                    item.nameTH.includes(searchInput) ||
                    item.nameEN.includes(searchInput)
                  );
                })
                .map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.nameTH}</td>
                      <td>{item.nameEN}</td>
                      <td>
                        <Button
                          variant="primary"
                          type="submit"
                          style={{ padding: "0 20px" }}
                          onClick={() => {
                            props.onSetCompanyId(item.id);
                          }}
                        >
                          เลือก
                        </Button>
                      </td>
                    </tr>
                  );
                })}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalVerticalCenterChooseCompany;
