import { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { ServerHost } from "../../ServerConfig/ServerConfig";
import "./AddCompany.css";
const AddCompany = () => {
  const file = useRef();
  const background_image = useRef();
  const [companyTableData, setCompanyTableData] = useState([
    { id: 0, nameTH: "ไม่พบ", nameEN: "ไม่พบ" },
  ]);
  const [companyData, setCompanyData] = useState({
    id: "",
    logo: undefined,
    background_image: undefined,
    nameTH: "",
    nameEN: "",
    address: "",
    district: "",
    subDistrict: "",
    province: "",
    zipCode: "",
    phone: "",
    workTime: "",
    website: "",
    email: "",
    googleMap: "",
  });
  function getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  function cleanData() {
    file.current.value = "";
    background_image.current.value = "";
    setCompanyData({
      id: "",
      logo: undefined,
      background_image: undefined,
      nameTH: "",
      nameEN: "",
      address: "",
      district: "",
      subDistrict: "",
      province: "",
      zipCode: "",
      phone: "",
      workTime: "",
      website: "",
      email: "",
      googleMap: "",
    });
  }
  function loadCompanyToTable() {
    fetch(`${ServerHost}/api/getCompany`)
      .then((response) => response.json())
      .then((result) => {
        setCompanyTableData(result.data);
      });
  }
  function saveCompany(e) {
    e.preventDefault();
    fetch(`${ServerHost}/api/saveCompany`, {
      method: "POST",
      body: JSON.stringify(companyData),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then(() => {
        loadCompanyToTable();
        cleanData();
      });
  }
  useEffect(() => {
    loadCompanyToTable();
  }, []);
  console.log(companyData);
  return (
    <>
      <div className="AddCompany">
        <h4>เพิ่มบริษัท</h4>
        <Form onSubmit={saveCompany}>
          <Form.Group className="mb-3">
            <Form.Label>รูปโลโก้</Form.Label>
            <Form.Control
              type="file"
              ref={file}
              onChange={(e) => {
                if (e.target.files[0].size > 512000) {
                  alert("ขนาดไฟล์ต้องไม่เกิน 500kB");
                  file.current.value = "";
                } else {
                  getBase64(e.target.files[0], (base) => {
                    setCompanyData({ ...companyData, logo: base });
                  });
                }
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>รูปพื้นหลัง</Form.Label>
            <Form.Control
              type="file"
              ref={background_image}
              onChange={(e) => {
                if (e.target.files[0].size > 512000) {
                  alert("ขนาดไฟล์ต้องไม่เกิน 500kB");
                  background_image.current.value = "";
                } else {
                  getBase64(e.target.files[0], (base) => {
                    setCompanyData({ ...companyData, background_image: base });
                  });
                }
              }}
            />
          </Form.Group>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridNameTH">
              <Form.Label>ชื่อบริษัทภาษาไทย</Form.Label>
              <Form.Control
                type="text"
                placeholder="ชื่อบริษัทภาษาไทย"
                autoComplete="off"
                value={companyData.nameTH}
                onChange={(e) =>
                  setCompanyData({ ...companyData, nameTH: e.target.value })
                }
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridNameEN">
              <Form.Label>ชื่อบริษัทภาษาอังกฤษ</Form.Label>
              <Form.Control
                type="text"
                placeholder="ชื่อบริษัทภาษาอังกฤษ"
                autoComplete="off"
                value={companyData.nameEN}
                onChange={(e) =>
                  setCompanyData({ ...companyData, nameEN: e.target.value })
                }
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridAddressNo">
              <Form.Label>ที่อยู่เลขที่</Form.Label>
              <Form.Control
                type="text"
                placeholder="ที่อยู่เลขที่"
                autoComplete="off"
                value={companyData.address}
                onChange={(e) =>
                  setCompanyData({ ...companyData, address: e.target.value })
                }
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridDistrict">
              <Form.Label>อำเภอ/เขต</Form.Label>
              <Form.Control
                type="text"
                placeholder="อำเภอ/เขต"
                autoComplete="off"
                value={companyData.district}
                onChange={(e) =>
                  setCompanyData({ ...companyData, district: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridSubDistrict">
              <Form.Label>ตำบล/แขวง</Form.Label>
              <Form.Control
                type="text"
                placeholder="ตำบล/แขวง"
                autoComplete="off"
                value={companyData.subDistrict}
                onChange={(e) =>
                  setCompanyData({
                    ...companyData,
                    subDistrict: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridDistrict">
              <Form.Label>จังหวัด</Form.Label>
              <Form.Control
                type="text"
                placeholder="จังหวัด"
                autoComplete="off"
                value={companyData.province}
                onChange={(e) =>
                  setCompanyData({ ...companyData, province: e.target.value })
                }
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridZip">
              <Form.Label>รหัสไปรษณีย์</Form.Label>
              <Form.Control
                type="text"
                placeholder="รหัสไปรษณีย์"
                autoComplete="off"
                value={companyData.zipCode}
                onChange={(e) =>
                  setCompanyData({ ...companyData, zipCode: e.target.value })
                }
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridPhones">
              <Form.Label>เบอร์โทร</Form.Label>
              <Form.Control
                type="text"
                placeholder="เบอร์โทร"
                autoComplete="off"
                value={companyData.phone}
                onChange={(e) =>
                  setCompanyData({ ...companyData, phone: e.target.value })
                }
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridSubWorkTime">
              <Form.Label>เวลาทำงาน</Form.Label>
              <Form.Control
                type="text"
                placeholder="เวลาทำงาน"
                autoComplete="off"
                value={companyData.workTime}
                onChange={(e) =>
                  setCompanyData({ ...companyData, workTime: e.target.value })
                }
              />
            </Form.Group>
          </Row>

          <Form.Group className="mb-3">
            <Form.Label>เว็บไซต์บริษัท</Form.Label>
            <Form.Control
              type="text"
              placeholder="เว็บไซต์บริษัท"
              autoComplete="off"
              value={companyData.website}
              onChange={(e) =>
                setCompanyData({ ...companyData, website: e.target.value })
              }
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>อีเมล์บริษัท</Form.Label>
            <Form.Control
              type="text"
              placeholder="อีเมล์บริษัท"
              autoComplete="off"
              value={companyData.email}
              onChange={(e) =>
                setCompanyData({ ...companyData, email: e.target.value })
              }
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>ตำแหน่งแผนที่</Form.Label>
            <Form.Control
              type="text"
              placeholder="ตำแหน่งแผนที่"
              autoComplete="off"
              value={companyData.googleMap}
              onChange={(e) =>
                setCompanyData({ ...companyData, googleMap: e.target.value })
              }
            />
          </Form.Group>

          <Button variant="primary" type="submit">
            {companyData.id !== "" ? "แก้ไข" : "บันทึก"}
          </Button>
          <Button variant="info" type="button" onClick={cleanData}>
            ล้างค่า
          </Button>
        </Form>
      </div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>ชื่อบริษัทไทย</th>
            <th>ชื่อบริษัทอังกฤษ</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {companyTableData.map((item, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.nameTH}</td>
                <td>{item.nameEN}</td>
                <td>
                  <Button
                    variant="primary"
                    type="submit"
                    style={{ padding: "0 20px" }}
                    onClick={() => {
                      fetch(`${ServerHost}/api/getCompanyForEdit?id=${item.id}`)
                        .then((response) => response.json())
                        .then((result) => {
                          setCompanyData(result.data[0]);
                        });
                    }}
                  >
                    แก้ไข
                  </Button>
                  <Button
                    variant="danger"
                    type="submit"
                    style={{ padding: "0 20px" }}
                    onClick={() => {
                      fetch(`${ServerHost}/api/removeCompany`, {
                        method: "POST",
                        body: JSON.stringify({ id: item.id }),
                        headers: {
                          "Content-Type": "application/json",
                        },
                      })
                        .then((response) => response.json())
                        .then((result) => {
                          alert(result.message);
                          loadCompanyToTable();
                        });
                    }}
                  >
                    ลบ
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

export default AddCompany;
