import { Container } from "react-bootstrap";
import CardJob from "../../UI/CardJob/CardJob";
import "./RecruitmentJobFromSameCompany.css";
const RecruitmentJobFromSameCompany = (props) => {
  return (
    <>
      <section className="RecruitmentJobFromSameCompanyCompanyTopic">
        <Container>
          <div className="RecruitmentJobFromSameCompanyCompanyTopicWrapper">
            <h1>Job From Same Company</h1>
            <div>
              <h1>{props.jobDetailData.job_from_same_company_qty} Jobs</h1>
              <h5>Available</h5>
            </div>
          </div>
        </Container>
      </section>
      <section className="RecruitmentJobFromSameCompany">
        <Container>
          {props.jobDetailData.job_from_same_company.map((item, index) => (
            <CardJob
              key={index}
              job_name={item.job_name}
              company_address_shortcut={`${props.jobDetailData.district},${props.jobDetailData.province}`}
              id={item.id}
            />
          ))}
        </Container>
      </section>
    </>
  );
};
export default RecruitmentJobFromSameCompany;
