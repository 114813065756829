import { useEffect, useState } from "react";
import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
import AddCompany from "./AddCompany/AddCompany";
import AddCompanyDetail from "./AddCompanyDetail/AddCompanyDetail";
import AddJob from "./AddJob/AddJob";
import AddJobDetail from "./AddJobDetail/AddJobDetail";
import "./AdminPage.css";
import LiveStreamSetting from "./LiveStreamSetting/LiveStreamSetting";
import Login from "./Login/Login";
const AdminPage = () => {
  const [isLogin, setIsLogin] = useState(localStorage.getItem("login"));

  function onLogin(username, password) {
    if (username === "admin" && password === "123456789") {
      localStorage.setItem("login", "true");
      setIsLogin(true);
    } else {
      alert("ชื่อผู้ใช้หรือรหัสผ่านผิด");
    }
  }
  useEffect(() => {
    setIsLogin(localStorage.getItem("login"));
  }, [isLogin]);
  return (
    <>
      {isLogin === null ? (
        <Login onLogin={onLogin} />
      ) : (
        <Container>
          <div className="AdminPage">
            <h2>ระบบหลังบ้าน</h2>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Row style={{ minHeight: "50vh" }}>
                <Col sm={3}>
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="first">ตั้งค่าไลฟ์วิดิโอ</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="AddCompany">เพิ่มบริษัท</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="AddCompanyDetail">
                        เพิ่มรายละเอียดบริษัท
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="AddJob">เพิ่มงาน</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="AddJobDetail">
                        เพิ่มรายละเอียดงาน
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col sm={9}>
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <LiveStreamSetting />
                    </Tab.Pane>
                    <Tab.Pane eventKey="AddCompany">
                      <AddCompany />
                    </Tab.Pane>
                    <Tab.Pane eventKey="AddCompanyDetail">
                      <AddCompanyDetail />
                    </Tab.Pane>
                    <Tab.Pane eventKey="AddJob">
                      <AddJob />
                    </Tab.Pane>
                    <Tab.Pane eventKey="AddJobDetail">
                      <AddJobDetail />
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </Container>
      )}
    </>
  );
};

export default AdminPage;
