import { Card, Col, Container, Row } from "react-bootstrap";
import "./CardMenu.css";

const CardMenu = () => {
  return (
    <section className="CardMenu">
      <Container className="CardMenuHeader">
        <h1>ศูนย์รวมบัณฑิตมากประสบการณ์</h1>
        <h2>Thai-Nichi Institute of Technology</h2>
        <Row className="sectionCardMenu">
          <Col sm={6} md={4} style={{ margin: "0 auto" }}>
            <Card
              className="CardMenuChoice"
              onClick={() => window.open("https://www.tni.ac.th", "_blank")}
            >
              <Card.Img
                className="CardMenuChoiceImg"
                variant="top"
                src={require("../../../Images/CardMenu1.jpg").default}
              />
              <Card.Body className="CardMenuBody">
                <Card.Title>TNI WEBSITE</Card.Title>
                <Card.Subtitle>เว็บไซต์แนะนำสถาบัน</Card.Subtitle>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={6} md={4} style={{ margin: "0 auto" }}>
            <Card
              className="CardMenuChoice"
              onClick={() =>
                window.open("https://www.facebook.com/training.tni", "_blank")
              }
            >
              <Card.Img
                className="CardMenuChoiceImg"
                variant="top"
                src={require("../../../Images/CardMenu2.jpg").default}
              />
              <Card.Body className="CardMenuBody">
                <Card.Title>TNI ACADEMIC</Card.Title>
                <Card.Subtitle>ศูนย์บริการงานวิชาการ</Card.Subtitle>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={6} md={4} style={{ margin: "0 auto" }}>
            <Card
              className="CardMenuChoice"
              onClick={() =>
                window.open("https://www.facebook.com/coopthainichi", "_blank")
              }
            >
              <Card.Img
                className="CardMenuChoiceImg"
                variant="top"
                src={require("../../../Images/CardMenu3.jpg").default}
              />
              <Card.Body className="CardMenuBody">
                <Card.Title>COOPERATIVE</Card.Title>
                <Card.Subtitle>ประชาสัมพันธ์สหกิจ</Card.Subtitle>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default CardMenu;
