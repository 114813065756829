import { Container } from "react-bootstrap";
import CardJob from "../../UI/CardJob/CardJob";

import "./RecruitmentCompanyDetail.css";
const RecruitmentCompanyDetail = (props) => {
  return (
    <>
      <section className="RecruitmentCompanyDetailTopic">
        <Container>
          <div className="RecruitmentCompanyDetailTopicWrapper">
            <h1>Recruitment Position From Same Company</h1>
            <div>
              <h1>{props.companyDetailData.job_from_same_company_qty} Jobs</h1>
              <h5>Available</h5>
            </div>
          </div>
        </Container>
      </section>
      <section className="RecruitmentCompanyJobDetailContact">
        <Container>
          {props.companyDetailData.job_from_same_company.map((item, index) => {
            console.log(item.id);
            return (
              <CardJob
                key={index}
                job_name={item.job_name}
                company_address_shortcut={`${item.district} ${item.province}`}
                id={item.id}
              />
            );
          })}
        </Container>
      </section>
    </>
  );
};

export default RecruitmentCompanyDetail;
