import { faEnvelope, faLocationArrow } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import RecruitmentJobFromSameCompany from "../../Layouts/RecruitmentJobFromSameCompany/RecruitmentJobFromSameCompany";
import ThereMoreCompany from "../../Layouts/ThereMoreCompany/ThereMoreCompany";
import { ServerHost } from "../../ServerConfig/ServerConfig";
import NoPage from "../NoPage/NoPage";
import "./JobDetail.css";
const JobDetail = () => {
  const [showWelfare, setShowWelfare] = useState(false);
  const [showQualification, setShowQualification] = useState(false);
  const [jobDetailData, setJobDetailData] = useState({
    id: 0,
    company_id: 0,
    logo: "",
    job_name: "",
    nameTH: "",
    district: "",
    province: "",
    website: "",
    phone: "",
    email: "",
    googleMap: "",
    job_requirement: [],
    job_qualification: [],
    job_welfare: [],
    job_from_same_company: [],
    job_from_same_company_qty: 0,
    company_interest: [],
  });
  const { id } = useParams();

  async function getDataToJobDetailPage() {
    const response = await fetch(`${ServerHost}/api/getJobInfoId?id=${id}`);
    const data = await response.json();
    setJobDetailData(data.job);
    setShowWelfare(false);
    setShowQualification(false);
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    getDataToJobDetailPage();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return (
    <>
      {jobDetailData.company_id === 0 && <NoPage></NoPage>}
      {jobDetailData.company_id !== 0 && (
        <>
          <Container>
            <section className="job_detail_header">
              <h1>{jobDetailData.job_name}</h1>
              <h2>
                {jobDetailData.district}, {jobDetailData.province}
              </h2>
            </section>
            <section className="job_from_company">
              <div className="job_from_company_logo">
                <div>
                  <img src={Buffer(jobDetailData.logo).toString()} alt="" />
                </div>
                <div className="job_from_company_name">
                  <h5>{jobDetailData.nameTH}</h5>
                  <h6>
                    <Link to={`/CompanyDetail/${jobDetailData.company_id}`}>
                      รายละเอียดบริษัท...
                    </Link>
                  </h6>
                </div>
              </div>
              <div className="job_from_company_tel">{jobDetailData.phone}</div>
            </section>
            <section className="job_company_detail">
              <Row>
                <Col sm="12" md="6" lg="6" className="job_detail_requirement">
                  <h2>รายละเอียดงาน</h2>
                  <ul>
                    {jobDetailData.job_requirement.map((item, index) => (
                      <li key={index}>{item.requirement}</li>
                    ))}
                  </ul>
                </Col>
                <Col
                  sm="12"
                  md="6"
                  lg="6"
                  className="job_detail_how_to_register"
                >
                  <h2 style={{ color: "#FF4040" }}>วิธีการสมัครงาน</h2>
                  <div className="process_register">
                    <div>
                      <FontAwesomeIcon icon={faEnvelope} />{" "}
                      <Link to="#">
                        สมัครผ่านทาง Email {jobDetailData.email}
                      </Link>
                    </div>
                    <div>
                      <FontAwesomeIcon icon={faLocationArrow} />{" "}
                      <a href={jobDetailData.googleMap}>
                        สมัครด้วยตนเอง ณ สำนักงานใหญ่ (Google Map)
                      </a>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm="12" md="6" lg="6" className="job_detail_qualification">
                  <h2>คุณสมบัติหลัก</h2>
                  <ul>
                    {showQualification === false &&
                      jobDetailData.job_qualification
                        .filter((item, index) => {
                          return index + 1 <= 5;
                        })
                        .map((item, index) => (
                          <li key={index}>{item.qualification}</li>
                        ))}
                    {showQualification === true &&
                      jobDetailData.job_qualification.map((item, index) => {
                        return <li key={index}>{item.qualification}</li>;
                      })}
                    <li
                      style={{
                        display: showQualification === true ? "none" : "block",
                      }}
                    >
                      {jobDetailData.job_qualification.length === 0 ? (
                        "ไม่ระบุ"
                      ) : (
                        <Link
                          to="#"
                          style={{
                            textDecoration: "none",
                          }}
                          onClick={() => setShowQualification(true)}
                        >
                          ดูเพิ่มเติม
                        </Link>
                      )}
                    </li>
                  </ul>
                </Col>
                <Col sm="12" md="6" lg="6" className="job_detail_welfare">
                  <h2>สวัสดิการ</h2>
                  <ul>
                    {showWelfare === false &&
                      jobDetailData.job_welfare
                        .filter((item, index) => {
                          console.log(index + 1 !== 5);
                          return index + 1 <= 5;
                        })
                        .map((item, index) => (
                          <li key={index}>{item.welfare}</li>
                        ))}
                    {showWelfare === true &&
                      jobDetailData.job_welfare.map((item, index) => {
                        return <li key={index}>{item.welfare}</li>;
                      })}
                    <li
                      style={{
                        display: showWelfare === true ? "none" : "block",
                      }}
                    >
                      {jobDetailData.job_welfare.length === 0 ? (
                        "ไม่ระบุ"
                      ) : (
                        <Link
                          to="#"
                          style={{
                            textDecoration: "none",
                          }}
                          onClick={() => setShowWelfare(true)}
                        >
                          ดูเพิ่มเติม
                        </Link>
                      )}
                    </li>
                  </ul>
                </Col>
              </Row>
            </section>
          </Container>
          <RecruitmentJobFromSameCompany jobDetailData={jobDetailData} />
          <ThereMoreCompany DetailData={jobDetailData} />
        </>
      )}
    </>
  );
};

export default JobDetail;
