import "./App.css";
import Footer from "./Component/Layouts/Footer/Footer";
import NavbarMenu from "./Component/Layouts/NavbarMenu/NavbarMenu";

import RoutePath from "./RoutePath";

function App() {
  return (
    <>
      <NavbarMenu />
      <RoutePath></RoutePath>
      <Footer />
    </>
  );
}

export default App;
