const NoPage = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        fontSize: "72px",
      }}
    >
      <img
        src={require("../../../Images/download spinner.gif").default}
        alt="Loading..."
      />
    </div>
  );
};

export default NoPage;
