import "./CompanyDetailBanner.css";
import { Container } from "react-bootstrap";
const CompanyDetailBanner = (props) => {
  return (
    <div
      className="background_company_detail_banner"
      style={{ backgroundImage: `url(${props.background})` }}
    >
      <div className="position_abs_card_company_detail_banner">
        <Container>
          <div className="card_company_detail_banner">
            <div className="card_company_logo_detail">
              <div className="card_company_logo">
                <img src={props.logo} alt="" />
              </div>
              <div className="card_company_detail">
                <h1>{props.company_name_en}</h1>
                <h3>{props.company_name_th}</h3>
              </div>
            </div>
            <div className="card_company_subscribe_button">
              <a href={props.subscribe} target="blank">
                Subscribe
              </a>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default CompanyDetailBanner;
