import { Link } from "react-router-dom";
import "./CardJob.css";

const CardJob = (props) => {
  return (
    <div className="card_job">
      <div className="card_job_logo_detail">
        {props.logo !== undefined && (
          <div className="card_job_logo">
            <img src={props.logo} alt="" />
          </div>
        )}
        {props.logo !== undefined && (
          <div className="card_job_detail">
            <h1>{props.job_name}</h1>
            <h3>{props.company_address_shortcut}</h3>
          </div>
        )}
        {props.logo === undefined && (
          <div className="card_job_detail" style={{ margin: 0 }}>
            <h1>{props.job_name}</h1>
            <h3>{props.company_address_shortcut}</h3>
          </div>
        )}
      </div>
      <div className="card_job_view_button">
        <Link to={`/JobDetail/${props.id}`}>View Job</Link>
      </div>
    </div>
  );
};

export default CardJob;
