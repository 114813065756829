import { Card, Col, Container, Row } from "react-bootstrap";
import "./TniJobFairTimeLine.css";

const TniJobFairTimeLine = () => {
  return (
    <>
      <section className="TniJobFairTimeLineTopic">
        <Container className="TniJobFairTimeLineTopicHeader">
          <h1>
            TNIJOB FAIR <span>Timeline</span>
          </h1>
          <h2>วีดีโอย้อนหลัง มหกรรมจัดหางานบริษัทชั้นนำ</h2>
        </Container>
      </section>
      <section className="CardMenu" style={{ padding: 0 }}>
        <Container>
          <Row className="sectionCardMenu">
            <Col sm="12" md="6" lg="3">
              <Card className="CardMenuChoice">
                <iframe
                  width="auto"
                  height="315"
                  src="https://www.youtube.com/embed/XPSClOEjQD8"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
                <Card.Body className="CardMenuBody">
                  <Card.Title>TNI JOBFAIR</Card.Title>
                  <Card.Subtitle>2015</Card.Subtitle>
                </Card.Body>
              </Card>
            </Col>
            <Col sm="12" md="6" lg="3">
              <Card className="CardMenuChoice">
                <iframe
                  width="auto"
                  height="315"
                  src="https://www.youtube.com/embed/AyTSlato05A"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
                <Card.Body className="CardMenuBody">
                  <Card.Title>TNI JOBFAIR</Card.Title>
                  <Card.Subtitle>2016</Card.Subtitle>
                </Card.Body>
              </Card>
            </Col>
            <Col sm="12" md="6" lg="3">
              <Card className="CardMenuChoice">
                <iframe
                  width="auto"
                  height="315"
                  src="https://www.youtube.com/embed/b9Sd_oGeM7s"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
                <Card.Body className="CardMenuBody">
                  <Card.Title>TNI JOBFAIR</Card.Title>
                  <Card.Subtitle>2017</Card.Subtitle>
                </Card.Body>
              </Card>
            </Col>
            <Col sm="12" md="6" lg="3">
              <Card className="CardMenuChoice">
                <iframe
                  width="auto"
                  height="315"
                  src="https://www.youtube.com/embed/6MbN57rmCR8"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
                <Card.Body className="CardMenuBody">
                  <Card.Title>
                    <span style={{ fontSize: "18px" }}>
                      TNI Co-Operative Education
                    </span>
                  </Card.Title>
                  <Card.Subtitle>ขั้นตอนสมัครงาน</Card.Subtitle>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default TniJobFairTimeLine;
