import { Fragment } from "react";
import { Route, Switch } from "react-router";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import AdminPage from "./Component/AdminPages/AdminPage";
import Login from "./Component/AdminPages/Login/Login";
import ActivityArchive from "./Component/Pages/ActivityArchive/ActivityArchive";
import CompanyDetail from "./Component/Pages/CompanyDetail/CompanyDetail";
import Home from "./Component/Pages/Home/Home";
import JobDetail from "./Component/Pages/JobDetail/JobDetail";
import TniJobFair from "./Component/Pages/TniJobFair/TniJobFair";
import CardCompany from "./Component/UI/CardCompany/CardCompany";

const RoutePath = () => {
  return (
    <Fragment>
      <Switch>
        <Route exact path="/">
          <Redirect to="/Home">
            <Home />
          </Redirect>
        </Route>
        <Route path="/Home">
          <Home />
        </Route>
        <Route path="/Login">
          <Login />
        </Route>
        <Route path="/CardCompany">
          <CardCompany />
        </Route>
        <Route path="/ActivityArchive">
          <ActivityArchive />
        </Route>
        <Route path="/TniJobFair">
          <TniJobFair />
        </Route>
        <Route path="/CompanyDetail/:id">
          <CompanyDetail />
        </Route>
        <Route path="/CompanyDetail">
          <CompanyDetail />
        </Route>
        <Route path="/JobDetail/:id">
          <JobDetail />
        </Route>
        <Route path="/JobDetail">
          <JobDetail />
        </Route>
        <Route path="/Admin">
          <AdminPage />
        </Route>
      </Switch>
    </Fragment>
  );
};

export default RoutePath;
