import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { ServerHost } from "../../ServerConfig/ServerConfig";
import ModalVerticalCenterChooseJob from "../../UI/ModallVerticalCenterChooseJob/ModalVerticalCenterChooseJob";
import "./AddJobDetail.css";

const AddJobDetail = () => {
  const [jobDetails, setJobDetails] = useState({
    jobId: null,
    jobName: "",
    jobDetail: {
      job_requirement: [],
      job_qualification: [],
      job_welfare: [],
    },
  });
  const [modalShow, setModalShow] = useState(false);
  async function onSetJobId(id) {
    console.log(id);

    let [job, jobDetailRQW] = await Promise.all([
      fetch(`${ServerHost}/api/getJobForEdit?id=${id}`).then((response) =>
        response.json()
      ),
      fetch(`${ServerHost}/api/getJobDetailForEdit?id=${id}`).then((response) =>
        response.json()
      ),
    ]);
    setJobDetails({
      ...jobDetails,
      jobName: `บริษัท ${job.data[0].nameTH} ตำแหน่งงาน ${job.data[0].job_name}`,
      jobId: id,
      jobDetail: {
        job_requirement: [],
        job_qualification: [],
        job_welfare: [],
      },
    });
    setJobDetails({
      ...jobDetails,
      jobName: `บริษัท ${job.data[0].nameTH} ตำแหน่งงาน ${job.data[0].job_name}`,
      jobId: id,
      jobDetail: jobDetailRQW.data,
    });

    setModalShow(false);
  }

  function saveJobDetail(e) {
    e.preventDefault();
    fetch(`${ServerHost}/api/saveJobDetail`, {
      method: "POST",
      body: JSON.stringify({ jobDetail: jobDetails.jobDetail }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then(() => {
        alert("บันทึกสำเร็จ");
      });
  }
  function removeJobDetail(id, type) {
    fetch(`${ServerHost}/api/removeJobDetail`, {
      method: "POST",
      body: JSON.stringify({
        id: id,
        type: type,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((result) => {
        alert(result.message);
        onSetJobId(jobDetails.jobId);
      });
  }
  console.log(jobDetails);
  return (
    <div className="AddJobDetail">
      <div className="AddJobDetailHeader">
        <h4>เพิ่มรายละเอียดงาน {jobDetails.jobId}</h4>
      </div>
      <InputGroup className="mb-3">
        <Form.Control
          placeholder="เลือกงาน"
          aria-label="เลือกงาน"
          aria-describedby="basic-addon2"
          value={jobDetails.jobName}
          disabled
        />
        <Button
          variant="outline-secondary"
          id="button-addon2"
          onClick={() => setModalShow(true)}
        >
          เลือก
        </Button>
      </InputGroup>
      {jobDetails.jobId !== null && (
        <>
          <Form onSubmit={saveJobDetail}>
            <div style={{ position: "relative" }}>
              <Button
                className="AddTopicButton"
                variant="primary"
                type="button"
                style={{
                  position: "absolute",
                  right: 0,
                  top: "0px",
                  padding: "2px 10px 0px 10px",
                }}
                onClick={() => {
                  setJobDetails({
                    ...jobDetails,
                    jobDetail: {
                      ...jobDetails.jobDetail,
                      job_requirement: [
                        ...jobDetails.jobDetail.job_requirement,
                        { id: "", job_id: jobDetails.jobId, requirement: "" },
                      ],
                    },
                  });
                }}
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
              <Form.Label>เพิ่มความต้องการของงาน</Form.Label>
              {jobDetails.jobDetail.job_requirement.map((item, index) => {
                return (
                  <InputGroup className="mb-3" key={index}>
                    <Form.Control
                      placeholder="รายละเอียดงาน"
                      aria-label="รายละเอียดงาน"
                      aria-describedby="basic-addon2"
                      value={item.requirement}
                      onChange={(e) => {
                        setJobDetails({
                          ...jobDetails,
                          jobDetail: {
                            ...jobDetails.jobDetail,
                            job_requirement:
                              jobDetails.jobDetail.job_requirement.map(
                                (subItem, subIndex) => {
                                  if (index !== subIndex) return { ...subItem };
                                  else
                                    return {
                                      ...subItem,
                                      requirement: e.target.value,
                                    };
                                }
                              ),
                          },
                        });
                      }}
                    />
                    <Button
                      type="button"
                      variant="danger"
                      id="button-addon2"
                      onClick={() => {
                        removeJobDetail(item.id, "requirement");
                      }}
                    >
                      ลบ
                    </Button>
                  </InputGroup>
                );
              })}
            </div>
            <div style={{ position: "relative" }}>
              <Button
                className="AddTopicButton"
                variant="primary"
                type="button"
                style={{
                  position: "absolute",
                  right: 0,
                  top: "0px",
                  padding: "2px 10px 0px 10px",
                }}
                onClick={() => {
                  setJobDetails({
                    ...jobDetails,
                    jobDetail: {
                      ...jobDetails.jobDetail,
                      job_qualification: [
                        ...jobDetails.jobDetail.job_qualification,
                        { id: "", job_id: jobDetails.jobId, qualification: "" },
                      ],
                    },
                  });
                }}
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
              <Form.Label>เพิ่มคุณสมบัติ</Form.Label>
              {jobDetails.jobDetail.job_qualification.map((item, index) => {
                return (
                  <InputGroup className="mb-3" key={index}>
                    <Form.Control
                      placeholder="คุณสมบัติ"
                      aria-label="คุณสมบัติ"
                      aria-describedby="basic-addon2"
                      value={item.qualification}
                      onChange={(e) => {
                        setJobDetails({
                          ...jobDetails,
                          jobDetail: {
                            ...jobDetails.jobDetail,
                            job_qualification:
                              jobDetails.jobDetail.job_qualification.map(
                                (subItem, subIndex) => {
                                  if (index !== subIndex) return { ...subItem };
                                  else
                                    return {
                                      ...subItem,
                                      qualification: e.target.value,
                                    };
                                }
                              ),
                          },
                        });
                      }}
                    />
                    <Button
                      type="button"
                      variant="danger"
                      id="button-addon2"
                      onClick={() => {
                        removeJobDetail(item.id, "qualification");
                      }}
                    >
                      ลบ
                    </Button>
                  </InputGroup>
                );
              })}
            </div>
            <div style={{ position: "relative" }}>
              <Button
                className="AddTopicButton"
                variant="primary"
                type="button"
                style={{
                  position: "absolute",
                  right: 0,
                  top: "0px",
                  padding: "2px 10px 0px 10px",
                }}
                onClick={() => {
                  setJobDetails({
                    ...jobDetails,
                    jobDetail: {
                      ...jobDetails.jobDetail,
                      job_welfare: [
                        ...jobDetails.jobDetail.job_welfare,
                        { id: "", job_id: jobDetails.jobId, welfare: "" },
                      ],
                    },
                  });
                }}
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
              <Form.Label>เพิ่มสวัสดิการ</Form.Label>
              {jobDetails.jobDetail.job_welfare.map((item, index) => {
                return (
                  <InputGroup className="mb-3" key={index}>
                    <Form.Control
                      placeholder="สวัสดิการ"
                      aria-label="สวัสดิการ"
                      aria-describedby="basic-addon2"
                      value={item.welfare}
                      onChange={(e) => {
                        setJobDetails({
                          ...jobDetails,
                          jobDetail: {
                            ...jobDetails.jobDetail,
                            job_welfare: jobDetails.jobDetail.job_welfare.map(
                              (subItem, subIndex) => {
                                if (index !== subIndex) return { ...subItem };
                                else
                                  return {
                                    ...subItem,
                                    welfare: e.target.value,
                                  };
                              }
                            ),
                          },
                        });
                      }}
                    />
                    <Button
                      type="button"
                      variant="danger"
                      id="button-addon2"
                      onClick={() => {
                        removeJobDetail(item.id, "welfare");
                      }}
                    >
                      ลบ
                    </Button>
                  </InputGroup>
                );
              })}
            </div>
            <Button
              variant="primary"
              type="submit"
              disabled={
                !(
                  jobDetails.jobDetail.job_requirement.length > 0 ||
                  jobDetails.jobDetail.job_qualification.length > 0 ||
                  jobDetails.jobDetail.job_welfare.length > 0
                )
              }
            >
              Submit
            </Button>
          </Form>
        </>
      )}
      <ModalVerticalCenterChooseJob
        title={"งานที่มี"}
        show={modalShow}
        onHide={() => setModalShow(false)}
        onSetJobId={onSetJobId}
      />
    </div>
  );
};

export default AddJobDetail;
