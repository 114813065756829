import { Carousel } from "react-responsive-carousel";
import "./CarouselBanner.css";
const CarouselBanner = () => {
  return (
    <section className="Banner">
      <div className="CarouselWrapper">
        <Carousel
          autoPlay
          interval="5000"
          transitionTime="500"
          showThumbs={false}
          infiniteLoop={true}
          showArrows={true}
        >
          <div>
            <img
              alt=""
              src={require("../../../Images/Carousel1.jpg").default}
            />
          </div>
          <div>
            <img
              alt=""
              src={require("../../../Images/Carousel2.jpg").default}
            />
          </div>
        </Carousel>
      </div>
    </section>
  );
};

export default CarouselBanner;
