import { useCallback, useEffect, useState } from "react";
import FacebookLiveBanner from "../../Layouts/FacebookLiveBanner/FacebookLiveBanner";
import PartnerFinding from "../../Layouts/PartnerFinding/PartnerFinding";
import RecruitmentFinding from "../../Layouts/RecruitmentFinding/RecruitmentFinding";
import { ServerHost } from "../../ServerConfig/ServerConfig";

const TniJobFair = () => {
  const [jobFairData, setJobFairData] = useState({
    stream_link: "",
    partner: [],
    partnerQty: 0,
    job: [],
    jobType: "Engineer",
    jobQty: { all: 0, engineer: 0, it: 0, business: 0 },
    showMore: false,
  });
  const getDataForJobFairPage = useCallback(async () => {
    const [partner, job, partnerQty, jobQty, stream_link] = await Promise.all([
      fetch(`${ServerHost}/api/getCompany6Value`).then((response) =>
        response.json()
      ),
      jobFairData.showMore === false
        ? fetch(`${ServerHost}/api/getJob${jobFairData.jobType}4Value`).then(
            (response) => response.json()
          )
        : fetch(`${ServerHost}/api/getJob${jobFairData.jobType}AllValue`).then(
            (response) => response.json()
          ),
      fetch(`${ServerHost}/api/getCompanyQtyValue`).then((response) =>
        response.json()
      ),
      fetch(`${ServerHost}/api/getJobQtyMultiTypeValue`).then((response) =>
        response.json()
      ),
      fetch(`${ServerHost}/api/getLiveStream`).then((response) =>
        response.json()
      ),
    ]);

    setJobFairData({
      ...jobFairData,
      partner: partner.data,
      partnerQty: partnerQty.data[0].qty,
      job: job.data,
      jobQty: jobQty.data[0],
      stream_link: stream_link.data.link,
    });
  }, [jobFairData]);

  async function searchJobInput(searchValue) {
    if (searchValue === "") {
      const response = await fetch(
        `${ServerHost}/api/getJob${jobFairData.jobType}4Value`
      );
      const job = await response.json();
      setJobFairData({ ...jobFairData, job: job.data });
    } else {
      const response = await fetch(
        `${ServerHost}/api/getJobFinding?searchInput=${searchValue}`
      );
      const job = await response.json();
      setJobFairData({ ...jobFairData, job: job.data });
    }
  }

  async function searchCompanyInput(searchValue) {
    if (searchValue === "") {
      const response = await fetch(`${ServerHost}/api/getCompany6Value`);
      const partner = await response.json();
      setJobFairData({ ...jobFairData, partner: partner.data });
    } else {
      const response = await fetch(
        `${ServerHost}/api/getCompanyFinding?searchInput=${searchValue}`
      );
      const partner = await response.json();
      setJobFairData({ ...jobFairData, partner: partner.data });
    }
  }

  async function showMorePartner() {
    const partnerAll = await fetch(`${ServerHost}/api/getCompanyAllValue`).then(
      (response) => response.json()
    );

    setJobFairData({ ...jobFairData, partner: partnerAll.data });
  }

  async function showLessPartner() {
    const partnerAll = await fetch(`${ServerHost}/api/getCompany6Value`).then(
      (response) => response.json()
    );

    setJobFairData({ ...jobFairData, partner: partnerAll.data });
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    getDataForJobFairPage();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobFairData.jobType, jobFairData.showMore]);

  return (
    <>
      <FacebookLiveBanner stream_link={jobFairData.stream_link} />
      <PartnerFinding
        partner={jobFairData.partner}
        partnerQty={jobFairData.partnerQty}
        showMorePartner={showMorePartner}
        showLessPartner={showLessPartner}
        searchCompanyInput={searchCompanyInput}
      />
      <RecruitmentFinding
        job={jobFairData.job}
        jobQty={jobFairData.jobQty}
        showMore={jobFairData.showMore}
        setJobFairData={setJobFairData}
        jobFairData={jobFairData}
        searchJobInput={searchJobInput}
      />
    </>
  );
};

export default TniJobFair;
