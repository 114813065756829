import { Container } from "react-bootstrap";
/* import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; */
import "./FacebookLive.css";
const FacebookLive = (props) => {
  return (
    <section className="FacebookLive" style={{ paddingBottom: "100px" }}>
      <Container className="FacebookLiveHeader">
        {/*  <h1>
          <FontAwesomeIcon icon={faFacebook} />
          <span style={{ fontWeight: "500" }}>FACEBOOK</span>
          <span style={{ fontWeight: "400" }}>LIVE</span>
        </h1> */}
        <h2>มหกรรมจัดหางาน TNIJOB FAIR 2022</h2>

        <section>
          <div className="home_live_video_wrapper">
            <div className="home_live_video">
              <div className="home_iframe_live_video_container">
                <iframe
                  width="100%"
                  height="1000"
                  src={props.stream_link}
                  title="Facebook Live Stream TNIJOBFAIR"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  scrolling="no"
                ></iframe>
              </div>
            </div>
          </div>
          <div className="home_live_video_mobile">
            <iframe
              title="Facebook Live Stream TNIJOBFAIR"
              width="560"
              height="349"
              src={props.stream_link}
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
        </section>
      </Container>
    </section>
  );
};

export default FacebookLive;
