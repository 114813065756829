import logo from "../../../Images/logo.png";
import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap";
import "./NavbarMenu.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";

import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
const NavbarMenu = () => {
  const location = useLocation();
  console.log(location.pathname);
  return (
    <Navbar
      className="navBarMenu"
      expand="lg"
      style={{ backgroundColor: "#F4F4F4" }}
    >
      <Container fluid>
        <Navbar.Brand as={Link} to="/Home">
          <img className="logo" src={logo} alt="" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="my-2 my-lg-0 ml-auto ms-auto navBarMenuWrapper"
            style={{ maxHeight: "100px" }}
            navbarScroll
          >
            <Nav.Link href="https://www.tni.ac.th/" target="blank">
              แนะนำสถาบัน
            </Nav.Link>
            <Nav.Link href="https://www.tni.ac.th/home/faculty" target="blank">
              คณะและหลักสูตร
            </Nav.Link>
            <Nav.Link as={Link} to="/ActivityArchive">
              คลังกิจกรรม
            </Nav.Link>
            <Nav.Link as={Link} to="/TniJobFair">
              <span
                className={
                  location.pathname !== "/TniJobFair" ? "tnijobfairmenu" : ""
                }
              >
                TNIJOB FAIR {new Date().getFullYear()}
              </span>
            </Nav.Link>
            <NavDropdown
              title={<FontAwesomeIcon icon={faGlobe} />}
              id="navbarScrollingDropdown"
            >
              <NavDropdown.Item
                href="https://www.tni.ac.th/home/student_portfolios"
                target="blank"
              >
                คลังกิจกรรม
              </NavDropdown.Item>
              <NavDropdown.Item
                href="https://www.tni.ac.th/home/exchange_programs"
                target="blank"
              >
                TNIJOB FAIR {new Date().getFullYear()}
              </NavDropdown.Item>
              <NavDropdown.Item
                href="https://www.tni.ac.th/home/cooperatives"
                target="blank"
              >
                สหกิจศึกษาและฝึกงาน
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarMenu;
