import "./FacebookLiveBanner.css";
const FacebookLiveBanner = (props) => {
  return (
    <section>
      <div className="banner_live_video_wrapper">
        <div className="banner_live_video">
          <div className="iframe_live_video_container">
            <iframe
              width="100%"
              height="540"
              src={props.stream_link}
              title="Facebook Live Stream TNIJOBFAIR"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              scrolling="no"
            ></iframe>
          </div>
        </div>
      </div>
      <div className="banner_live_video_mobile">
        <iframe
          title="Facebook Live Stream TNIJOBFAIR"
          width="560"
          height="349"
          src={props.stream_link}
          frameBorder="0"
          allowFullScreen
        ></iframe>
      </div>
    </section>
  );
};

export default FacebookLiveBanner;
