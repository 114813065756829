import { useEffect, useState } from "react";
import { Button, Form, InputGroup, Table } from "react-bootstrap";
import { ServerHost } from "../../ServerConfig/ServerConfig";
import ModalVerticalCenterChooseCompany from "../../UI/ModalVerticalCenterChooseCompany/ModalVerticalCenterChooseCompany";
import "./AddJob.css";

const AddJob = () => {
  const [companyId, setCompanyId] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [job, setJob] = useState({
    id: "",
    company_id: companyId,
    job_name: "",
    engineer: 0,
    it: 0,
    business: 0,
  });
  const [jobTableDataAll, setJobTableDataAll] = useState([
    { id: 0, nameTH: "ไม่พบ", job_name: "ไม่พบ" },
  ]);
  const [jobTableData, setJobTableData] = useState([
    { id: 0, job_name: "ไม่พบ", engineer: 0, it: 0, business: 0 },
  ]);
  function cleanData() {
    setJob({
      id: "",
      company_id: companyId,
      job_name: "",
      engineer: 0,
      it: 0,
      business: 0,
    });
  }
  function onSetCompanyId(id) {
    fetch(`${ServerHost}/api/getCompanyOnlyOne?id=${id}`)
      .then((response) => response.json())
      .then((result) => {
        setJob({ ...job, company_id: id });
        loadJobToTable(id);
        setCompanyName(result.data[0].nameTH);
        setModalShow(false);
        setCompanyId(id);
      });
  }
  function saveJob(e) {
    e.preventDefault();
    fetch(`${ServerHost}/api/saveJob`, {
      method: "POST",
      body: JSON.stringify({ job }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then(() => {
        loadJobToTable(companyId);
        cleanData();
        alert("บันทึกสำเร็จ");
      });
  }
  function loadJobAllToTable() {
    fetch(`${ServerHost}/api/getJobAll`)
      .then((response) => response.json())
      .then((result) => {
        setJobTableDataAll(result.data);
      });
  }
  function loadJobToTable(id) {
    fetch(`${ServerHost}/api/getJob?companyId=${id}`)
      .then((response) => response.json())
      .then((result) => {
        setJobTableData(result.data);
      });
  }
  console.log(job);
  useEffect(() => {
    loadJobAllToTable();
  }, []);
  return (
    <div className="AddJob">
      <div className="AddJobHeader">
        <h4>เพิ่มงาน</h4>
      </div>

      <Form onSubmit={saveJob} style={{ marginBottom: 15 }}>
        <InputGroup className="mb-3">
          <Form.Control type="hidden" value={job.id} />
          <Form.Control
            placeholder="เลือกบริษัท"
            aria-label="เลือกบริษัท"
            aria-describedby="basic-addon2"
            value={companyName}
            disabled
          />
          <Button
            variant="outline-secondary"
            id="button-addon2"
            onClick={() => setModalShow(true)}
          >
            เลือก
          </Button>
        </InputGroup>

        {companyId !== null && (
          <>
            <Form.Group className="mb-3">
              <Form.Label>ชื่องาน</Form.Label>
              <Form.Control
                type="text"
                placeholder="ชื่องาน"
                autoComplete="off"
                value={job.job_name}
                onChange={(e) => {
                  setJob({ ...job, job_name: e.target.value });
                }}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Label>งานสำหรับ</Form.Label>
              <Form.Check
                type="checkbox"
                label="วิศวกรรมศาสตร์"
                checked={job.engineer}
                onChange={() => setJob({ ...job, engineer: !job.engineer })}
              />
              <Form.Check
                type="checkbox"
                label="เทคโนโลยีสารสนเทศ"
                checked={job.it}
                onChange={() => setJob({ ...job, it: !job.it })}
              />
              <Form.Check
                type="checkbox"
                label="บริหารธุรกิจ"
                checked={job.business}
                onChange={() => setJob({ ...job, business: !job.business })}
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              บันทึก
            </Button>
            <Button variant="info" type="button" onClick={() => cleanData()}>
              ล้างค่า
            </Button>
          </>
        )}
      </Form>
      {companyName === "" && (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>ชื่อบริษัทไทย</th>
              <th>ชื่องาน</th>
            </tr>
          </thead>
          <tbody>
            {jobTableDataAll.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.nameTH}</td>
                  <td>{item.job_name}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}

      {companyName !== "" && (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>ชื่องาน</th>
              <th>งานสำหรับ</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {jobTableData.map((item, index) => {
              console.log(item);
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.job_name}</td>
                  <td>
                    {item.engineer !== 0 && "วิศวกรรมศาสตร์"}
                    {item.it !== 0 && " เทคโนโลยีสารสนเทศ"}
                    {item.business !== 0 && " บริหารธุริกจ"}
                  </td>

                  <td style={{ textAlign: "right" }}>
                    <Button
                      variant="primary"
                      type="submit"
                      style={{ padding: "0 20px" }}
                      onClick={() => {
                        fetch(`${ServerHost}/api/getJobForEdit?id=${item.id}`)
                          .then((response) => response.json())
                          .then((result) => {
                            setJob(result.data[0]);
                          });
                      }}
                    >
                      แก้ไข
                    </Button>
                    <Button
                      variant="danger"
                      type="submit"
                      style={{ padding: "0 20px" }}
                      onClick={() => {
                        fetch(`${ServerHost}/api/removeJob`, {
                          method: "POST",
                          body: JSON.stringify({ id: item.id }),
                          headers: {
                            "Content-Type": "application/json",
                          },
                        })
                          .then((response) => response.json())
                          .then((result) => {
                            alert(result.message);
                            loadJobToTable(companyId);
                          });
                      }}
                    >
                      ลบ
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}

      <ModalVerticalCenterChooseCompany
        title={"บริษัทที่มี"}
        show={modalShow}
        onHide={() => setModalShow(false)}
        onSetCompanyId={onSetCompanyId}
      />
    </div>
  );
};

export default AddJob;
