import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CompanyDetailBanner from "../../Layouts/CompanyDetailBanner/CompanyDetailBanner";
import CompanyDetailContact from "../../Layouts/CompanyDetailContact/CompanyDetailContact";
import RecruitmentCompanyDetail from "../../Layouts/RecruitmentCompanyDetail/RecruitmentCompanyDetail";
import ThereMoreCompany from "../../Layouts/ThereMoreCompany/ThereMoreCompany";
import { ServerHost } from "../../ServerConfig/ServerConfig";
import NoPage from "../NoPage/NoPage";

const CompanyDetail = () => {
  const { id } = useParams();

  const [companyDetailData, setCompanyDetailData] = useState({
    company_id: 0,
    logo: "",
    background_image: "",
    nameTH: "",
    nameEN: "",
    website: "",
    email: "",
    phone: "",
    address: "",
    district: "",
    subDistrict: "",
    province: "",
    zipCode: "",
    workTime: "",
    company_topic_and_detail: [],
    job_from_same_company: [],
    job_from_same_company_qty: 0,
    company_interest: [],
  });
  async function getDataToCompanyDetail() {
    console.log(id);
    const response = await fetch(
      `${ServerHost}/api/getDataToCompanyDetail?id=${id}`
    );
    const data = await response.json();
    setCompanyDetailData(data.data);
    console.log(data.data);
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    getDataToCompanyDetail();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return (
    <>
      {companyDetailData.company_id === 0 && <NoPage></NoPage>}
      {companyDetailData.company_id !== 0 && (
        <>
          <CompanyDetailBanner
            background={Buffer(companyDetailData.background_image).toString()}
            logo={Buffer(companyDetailData.logo).toString()}
            company_name_en={companyDetailData.nameEN}
            company_name_th={companyDetailData.nameTH}
            subscribe={companyDetailData.website}
          />
          <CompanyDetailContact companyDetailData={companyDetailData} />
          <RecruitmentCompanyDetail companyDetailData={companyDetailData} />
          <ThereMoreCompany DetailData={companyDetailData} />
        </>
      )}
    </>
  );
};

export default CompanyDetail;
